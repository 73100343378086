import gql from 'graphql-tag';

const conceptosNominasTableQuery = gql`
    query conceptosNominas($whereConditions:ConceptosNominasWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        conceptosNominas(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                codigo,
                codigo_sat_id,
                descripcion,
                porcentaje,
                formula_id,
                graba_imss,
                graba_isr,
                tipo,
                graba_impuesto_estatal,
                SAT,
                campos{
                    nombre,
                    tipo,
                    codigo,
                    orden
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const conceptosNominas = gql`
    query conceptosNominas($whereConditions:ConceptosNominasWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String! ) {
        conceptosNominas(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: ASC}]) {
            data {
                id,
                codigo,
                descripcion,
                funcion_nombre,
                campos_adicionales,
                SAT,
                tipo_complemento_imss
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { conceptosNominasTableQuery, conceptosNominas };

export default queries;
